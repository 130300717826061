import React, {useMemo} from "react";
import styles from "./style.module.css";
import SoMe from "../SoMe";
import {Link} from 'react-router-dom';
import {
    CID,
    COMPANY_ADDRESS_LINE_1,
    COMPANY_ADDRESS_LINE_2,
    COMPANY_NAME,
    PRIVACY_POLICY_LINK,
    RESEARCH_PAPERS_LINK,
    TAX_ID,
    TERMS_LINK
} from "../../enviroment";
import CompanySchema from "../CompanySchema";
import {t} from "../../intl/Intl";
import {BLOG_LINK, BUY_LINK, CONTACT_LINK, EDUCATE_LINK, LEAD_LINK} from "../../App";

const Footer = ({marginTop = false}) => {

    const logoSrc = useMemo(() =>  '/images/peptys_logo_white.svg', []);
    const currentYear = useMemo(() => new Date().getFullYear(), []);

    return (
        <>
        <footer className={`${styles.containerFluid} ${marginTop ? styles.marginTop : ''}`}>
            <div className={"container"}>
                <div className={styles.container}>
                <div className={styles.row}>

                    <div className={styles.columnMain}>
                        <div className={styles.logoContainer}>
                            <img src={logoSrc} alt={"PEPTYS logo"} className={styles.logo}
                                 width={150} height={48} fetchpriority={"low"}/>
                            <SoMe />
                        </div>
                        <div className={styles.columnText}>
                            {t('footerText')}
                        </div>
                        <div className={styles.buttonsContainer}>
                            <Link to={BUY_LINK} className={styles.button}>{t('footerActionButtonLabel')}</Link>
                            <Link to={LEAD_LINK} className={styles.buttonSecondary}>{t('linkForDoctors')}</Link>
                        </div>
                    </div>

                    <div className={styles.columnTwo}>
                        <div className={styles.columnTitle}>{t('footerCol1Title')}</div>
                        <div className={styles.columnText}>{COMPANY_NAME}<br/>
                            {COMPANY_ADDRESS_LINE_1}<br/>
                            {COMPANY_ADDRESS_LINE_2}<br/>
                            <br />
                            IČ: {CID}<br/>
                            DIČ: {TAX_ID}
                        </div>
                    </div>

                    <div className={styles.columnThree}>
                        <div className={styles.columnTitle}>{t('footerCol2Title')}</div>
                        <Link to={EDUCATE_LINK} className={styles.columnLink}>{t('linkEducate')}</Link>
                        <Link to={BUY_LINK} className={styles.columnLink}>{t('linkApplication')}</Link>
                        <a href={RESEARCH_PAPERS_LINK} target={"_blank"} rel={"noreferrer"} className={styles.columnLink}>{t('linkResearchPapers')}</a>
                        <Link to={BLOG_LINK} className={styles.columnLink}>{t('linkBlog')}</Link>
                    </div>

                    <div className={styles.columnFour}>
                    <div className={styles.columnTitle}>{t('footerCol3Title')}</div>
                        <Link to={CONTACT_LINK} className={styles.columnLink}>{t('linkContact')}</Link>
                        {/*<Link to={BLOG_LINK} className={styles.columnLink}>{t('linkBlog')}</Link>*/}
                        <a href={TERMS_LINK} target={"_blank"} rel={"noreferrer"} className={styles.columnLink}>{t('linkTerms')}</a>
                        <a href={PRIVACY_POLICY_LINK} target={"_blank"} rel={"noreferrer"} className={styles.columnLink}>{t('linkPrivacyPolicy')}</a>
                    </div>
                </div>

                <div className={styles.copyrightContainer}>
                    <div className={styles.copyrightText}>© {COMPANY_NAME} {currentYear}</div>
                </div>

                </div>
            </div>
        </footer>
            <CompanySchema />
        </>
);
}

export default React.memo(Footer);